/* KISS style */

.kiss-sidebar {
  z-index: 2;
  min-width: 0;
  max-width: 80vw;
  width: 80vw;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 0;
  bottom: 0;
  background: #ffffff;
  visibility: hidden;

  @include kiss-media('sm') {
    width: 16.25rem;
    max-width: 16.25rem;
  }

  &.open {
    transform: translateX(0) !important;
    visibility: visible;
  }

  &.locked-open {
    position: relative;
    transform: translateX(0);
    visibility: visible;
  }

  &.left-positioned {
    left: 0;
    transform: translateX(-100%);
  }

  &.right-positioned {
    right: 0;
    transform: translateX(100%);
  }

  &.animate {
    transition-property: transform, width, min-width, max-width, visibility;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
  }
}
