@mixin input-base() {
  border: 1px solid rgba(131, 131, 131, 0.4);
  border-radius: 8px;
  font-size: 14px;
  line-height: 19px;
  outline: none;
  font-weight: 400;
  position: relative;

  &.ng-dirty.ng-invalid {
    border-color: $primary-color;
    background-color: $light-red;
  }
}

@mixin input-outline-base() {
  background: $bg-color-11;
  border-radius: 8px;
  font-size: 14px;
  line-height: 19px;
  outline: none;
  border: none;
  font-weight: 400;
}

@mixin input-container-base() {
  border: 1px solid rgba(131, 131, 131, 0.4);
  border-radius: 8px;

  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 19px;

  &.ng-dirty.ng-invalid {
    border-color: $primary-color;

    > * {
      background-color: $light-red;
    }
  }
}

/* OOBLEE-INPUT */
.ooblee-input {
  @include input-base();

  color: $text-color;
  padding: 10px;
  min-width: 0;

  &--outline {
    @include input-outline-base();
    padding: 14px;
    min-width: 0;
    color: $text-color;
  }
}
/* OOBLEE-INPUT */

/* OOBLEE-TEXTAREA */
.ooblee-textarea {
  @include input-container-base();
  overflow: hidden;
  color: $text-color;

  textarea {
    padding: 10px 15px;
    min-height: 200px;
    resize: none;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
  }
}
/* !OOBLEE-TEXTAREA */

/* OOBLEE-SEARCH */
.ooblee-search {
  @include input-container-base();

  padding: 7.5px 10px;
  color: $text-color;
  overflow: hidden;

  input {
    flex: 1 1;
    border: none;
    background: transparent;
    outline: none;
    padding: 0;
    line-height: 19px;
    min-width: 0;
    font-weight: 400;
  }

  &__icon {
    width: 14px;
    height: 14px;

    &--small {
      width: 12px;
      height: 12px;
    }
  }

  &__btn {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: none;
    color: $text-color;

    &--pre {
      margin-right: 6px;
    }

    &--post {
      margin-left: 6px;
    }
  }
}
/* !OOBLEE-SEARCH */

/* OOBLEE-RADIO */
.ooblee-radio {
  $font-size: 1rem;
  $circle-size: 12px;
  $inner-circle-scale: 0.4;
  $padding-left: $circle-size;
  $circle-left-position: -$circle-size;

  padding-left: $padding-left;
  display: flex;
  align-items: center;

  input[type='radio'] {
    display: none;
  }

  label {
    padding-left: 10px;
    cursor: pointer;
    position: relative;
    font-size: $font-size;

    &::before {
      content: '';
      position: absolute;
      width: $circle-size;
      height: $circle-size;
      background-color: transparent;
      border: 1px solid $text-color-3;
      border-radius: 50%;
      top: 50%;
      left: $circle-left-position;
      transform: translateY(-50%);
      transition: border-color 400ms ease;
    }

    &::after {
      content: '';
      position: absolute;
      width: $circle-size;
      height: $circle-size;
      background-color: $primary-color;
      border: 1px solid $primary-color;
      border-radius: 50%;
      top: 50%;
      left: $circle-left-position;
      transform: translateY(-50%) scale(0);
      transition: transform 400ms ease;
    }
  }

  input[type='radio']:checked {
    + label {
      &::before {
        border-color: $primary-color;
      }

      &::after {
        transform: translateY(-50%) scale($inner-circle-scale);
      }
    }
  }
}
/* OOBLEE-RADIO */

/* OOBLEE-REQUIRED-TEXT */
.ooblee-error-msg {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: $primary-color;
}

.ooblee-warning-msg {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: $warning;
}
/* !OOBLEE-REQUIRED-TEXT */

/* OOBLEE-INPUT-FILE */
.ooblee-input-file {
  &__webkit {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;

    &::-webkit-file-upload-button,
    &::file-selector-button {
      border-radius: 8px;
      background: transparent;
      border: 1px solid $border-1;
      text-align: center;
      cursor: pointer;
      padding: 10px;
      color: $text-color;
      margin-right: 10px;

      font-weight: 600;
    }
  }
}
/* !OOBLEE-INPUT-FILE */
