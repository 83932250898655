.kiss-checkbox {
  &__checkmark-container {
    outline: 1px solid black;
    width: 20px;
    height: 20px;
  }

  &__checkmark {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
  }

  &__checkmark svg {
    color: #ffffff;
    width: 100%;
    height: auto;
  }

  &__content {
    &:empty {
      display: none;
    }
  }
}
