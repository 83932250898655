.kiss-menu-shop-select {
  padding: 1rem 0 1rem 1rem;
  min-width: 16.25rem;

  .kiss-menu-item {
    min-height: 2.875rem;
    border-radius: 0.5rem;
    padding: 0.438rem 0.625rem;

    &:not(:last-of-type) {
      border-bottom: none;
      margin-bottom: 0.625rem;
    }
  }
}
