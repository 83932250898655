.kiss-menu-content {
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 30%);
  border-radius: 4px;
  position: absolute;
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.kiss-menu-item {
  min-width: 170px;
  padding: 12px 16px;
  border: none;
  background: transparent;
}
