/* KISS BACKDROP */

.kiss-backdrop {
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

/* !KISS BACKDROP */
