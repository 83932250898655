/*
This file is used to contain all component imports.
Files inside this folder should contain all styles relating to a reusable component.
*/

@import 'dialogs/default-dialog';
@import 'dialogs/view-order-dialog';
@import 'dialogs/view-item-dialog';
@import 'dialogs/view-reservations-dialog';
@import 'dialogs/wallet-csv-export-dialog';
@import 'dialogs/swf-event-view-dialog';
@import 'dialogs/new-location-setting-dialog';
@import 'dialogs/qr-code-dialog';
@import 'ooblee-tab';
@import 'stepper';
