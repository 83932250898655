.qr-code-dialog {
  .kiss-dialog {
    &__content-container {
      background: $bg-color-4;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
    }

    &__content {
      outline: none;

      & > * {
        background: $bg-color-3;
        border-radius: 16px;
        max-width: 850px;
        width: 96rem;
        max-height: 90vh;
        min-height: 65vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
