/*KISS NAVIGATION SIDEBAR */

.kiss-navigation-sidebar {
  .kiss-navigation {
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    padding: 30px 0 15px 0;

    .nav-link,
    .nav-list {
      min-height: 56px;
    }

    .nav-children {
      .nav-link {
        min-height: 48px;
      }
    }

    .nav-group-title,
    .nav-group-translation {
      min-height: 20px;
    }

    &.folded {
      .nav-title,
      .nav-translation,
      .nav-list-icon,
      .nav-children {
        display: none;
      }
    }

    .nav-seperator {
      height: 20px;
      padding: 0 16px;
      display: flex;
      align-items: center;

      &::before {
        content: '';
        display: block;
        border-top: 1px solid;
        flex: 1 1;
      }
    }
  }

  .nav-expand-btn {
    position: absolute;
    top: 2.75rem;
    right: 0;
  }
}

/* !KISS NAVIGATION SIDEBAR */
