/* KISS MENU */
.kiss-menu-content {
  background: $bg-color-3;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  padding: 3px 0;

  .kiss-menu-item {
    color: $text-color;
    font-weight: 400;
    padding: 0 20px;
    min-height: 50px;
    display: flex;
    align-items: center;
    min-width: 0;
    width: 100%;
    background: $bg-color-3;

    &:not(:last-of-type) {
      border-bottom: 0.5px solid $border-1;
    }

    &:hover {
      background: var(--pallete-gray-6, #f2f2f2);
    }
  }
}

.kiss-menu {
  &__action {
    &__icon {
      width: 16px;
      height: 16px;

      &--pre {
        margin-right: 6px;
      }

      &--post {
        margin-left: 6px;
      }
    }
  }
}
/* !KISS MENU */
