.kiss-navigation {
  overflow-y: auto;
  overflow-x: hidden;
  white-space: nowrap;
  padding: 30px 0 15px 0;
}

.kiss-navigation-group {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  list-style-type: none;

  .nav-group-title,
  .nav-group-translation {
    padding: 0 16px;
    flex: 1 1;
    opacity: 0.7;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.kiss-navigation-item {
  .nav-link {
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
    flex: 1 1;
    font-weight: 600;
    color: inherit;
    cursor: pointer;
  }

  .nav-icon {
    margin: 0 0 0 16px;
    flex: 0 0 32px;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    img,
    i {
      color: inherit;
      width: 15px;
      height: auto;
      font-size: 15px;
    }

    &__svg {
      color: inherit;
      min-width: 15px;
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .nav-title,
  .nav-translation {
    padding: 0 16px;
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.kiss-navigation-list {
  .nav-list {
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    flex: 1 1;
    cursor: pointer;
    color: inherit;
    user-select: none;
  }

  .nav-icon {
    margin: 0 0 0 16px;
    flex: 0 0 32px;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    img,
    i {
      color: inherit;
      width: 15px;
      height: auto;
      font-size: 15px;
      ::before {
        font-size: 15px;
      }
    }

    &__svg {
      color: inherit;
      min-width: 15px;
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .nav-title,
  .nav-translation {
    padding: 0 16px;
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
  }

  .nav-list-icon {
    margin: 0 16px 0 0;
    flex: 0 0 32px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      display: flex;
      justify-content: center;
      align-items: center;
      color: inherit;
      width: 15px;
      height: auto;
      ::before {
        font-size: 15px;
      }
    }
  }

  .nav-children {
    padding-left: 48px;
  }
}
