.default-dialog {
  .kiss-dialog {
    &__content-container {
      background: $bg-color-4;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content {
      outline: none;
    }
  }
}

.default-dialog__wide {
  .kiss-dialog {
    &__content-container {
      background: $bg-color-4;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content {
      outline: none;
    }
  }

  .default-dialog__container {
    max-width: 700px !important;
  }
}
