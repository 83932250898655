/* OOBLEE-SELECT FORM TWO ROW */
.ooblee-select-form-two-row .kiss-select {
  &__dropdown {
    border-radius: 0.25rem;
    box-shadow: $box-shadow-1;
    padding: 0;
  }

  &__option-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .kiss-checkbox {
      margin-right: 12px;
    }
  }

  &__option {
    flex: 1 0 200px;
    padding: 0.438rem 0.75rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 24px;

    &--selected:not(&--checkbox-shown) {
      background: lighten($color: $primary-color, $amount: 7);
      color: $text-color-6;
    }

    &--multiple {
      background: initial;
      color: initial;
    }

    &:hover:not(&--selected) {
      background: #e2e2e2;
      color: initial;
    }
  }
}
/* OOBLEE-SELECT TWO ROW */

.kiss-select-option {
  &__pre-text {
    background: lighten($color: $primary-color, $amount: 7);
    color: $text-color-6;
    padding: 0.5rem 0.875rem;
    flex: 1 0 100%;
    position: sticky;
    top: 0;
    z-index: 2;
  }

  &__post-text {
    color: $text-color;
    background: $bg-color-3;
    padding: 0.938rem 0.875rem;
    flex: 1 0 100%;
    position: sticky;
    bottom: -1px;
    z-index: 2;
    border-top: 1px solid $border-2;
  }

  &__link {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-decoration-line: underline;
    color: $text-color-5;
    cursor: pointer;
  }
}

.kiss-select-container {
  &--250 .kiss-select__option-container {
    max-height: 250px;
  }
}
