@mixin btn-base() {
  /* BASE */
  border-radius: 8px;
  background: transparent;
  border: 1px solid transparent;
  text-align: center;
  cursor: pointer;
  padding: 0;
  /* !BASE */

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.ooblee-btn {
  @include btn-base();

  &__flat {
    @include btn-base();

    padding: 9px 15px;
    &--primary {
      background: $primary-color;
      color: #ffffff;

      &[disabled] {
        opacity: 0.6;
      }
    }
  }

  &__stroked {
    @include btn-base();

    padding: 9px 15px;

    &--primary {
      border-color: $border-1;
      color: $primary-color;
    }

    &--normal {
      border-color: $border-1;
      color: $text-color;
    }

    &--primary-all {
      border-color: $primary-color;
      color: $primary-color;

      &:hover {
        background-color: $bg-color-5;
      }
    }

    &--confirm {
      border-color: $dark-green;
      color: $dark-green;
      background-color: $light-green;

      &--hover {
        background-color: transparent;

        &:hover {
          background-color: $light-green;
        }
      }
    }

    &--error {
      border-color: $primary-color;
      color: $primary-color;
      background-color: $bg-color-5;

      &--hover {
        background-color: transparent;

        &:hover {
          background-color: $bg-color-5;
        }
      }
    }
    &--confirm-order {
      border-color: $dark-green;
      color: $dark-green;
      background-color: $light-green;

      &--hover {
        background-color: $light-green;

        &:hover {
          background-color: $light-green-hover;
        }
      }
    }
    &--reject {
      border-color: $primary-color;
      color: $primary-color;

      &--hover {
        background-color: $bg-color-8;

        &:hover {
          background-color: $bg-color-7;
        }
      }
    }
  }

  &__transparent {
    @include btn-base();

    padding: 9px 15px;
    &--primary {
      color: $text-color-3;
    }
  }

  &__icon {
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--pre {
      margin-right: 5px;
    }

    &--post {
      margin-left: 5px;
    }

    &--md {
      width: 20px;
      height: 20px;
    }

    &--radius {
      border-radius: 50%;
    }

    &--bg {
      &--gray {
        background-color: $bg-color-5;
      }
    }
  }

  &__circle-icon {
    @include btn-base();
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;

    &--reject {
      border-color: $primary-color;
      color: $primary-color;

      &:hover {
        background-color: $bg-color-5;
      }
    }

    &--confirm {
      border-color: $dark-green;
      color: $dark-green;

      &:hover {
        background-color: $light-green;
      }
    }
  }

  &__btn-icon {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--bg {
    &--9 {
      background: $bg-color-9;
    }
  }

  &--radius {
    &--50 {
      border-radius: 50%;
    }
  }
}
