.ooblee-checkbox.kiss-checkbox {
  .kiss-checkbox__checkmark-container {
    outline: 1px solid $border-3;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    user-select: none;
  }

  .kiss-checkbox__label {
    display: flex;
    align-items: center;
    user-select: all;
  }

  .kiss-checkbox__content {
    margin-left: 0.75em;
  }

  .kiss-checkbox__checkmark {
    background-color: $primary-color;

    svg {
      height: 10px;
    }
  }

  &.checked {
    .kiss-checkbox__checkmark-container {
      outline-color: $primary-color;
      background-color: $primary-color;
    }
  }
}
