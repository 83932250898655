.kiss-tooltip {
  &__tag {
    background-color: var(--primary-white, #ffffff);
    color: var(--pallete-gray-3, #828282);
    font-size: 12px;
    padding: 2px 8px;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    line-height: 18px;
    user-select: none;
  }
}
