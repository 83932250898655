.view-reservations-dialog {
  .kiss-dialog {
    &__content-container {
      background: $bg-color-4;
      display: flex;
      justify-content: center;
      padding: 10vh 0 0 0;
    }

    &__content {
      outline: none;

      & > * {
        background: $bg-color-3;
        border-radius: 16px;
        width: 800px;
        height: 100%;
        max-width: 90vw;
        max-height: 85vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
