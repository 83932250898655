.kiss-breadcrumbs {
  &__no-link,
  &__link {
    font-weight: var(--font-weight-bold, 700);
    font-size: 1.5rem;
    line-height: var(--line-height-lg, 2rem);
  }

  &__icon {
    font-size: 2rem;
    margin: 0 0.5rem;
  }
}
