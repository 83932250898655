/* OOBLEE-SELECT filter*/
.ooblee-select-filter.kiss-select {
  border: 1px solid $border-3;
  border-radius: 0.5rem;
  color: $text-color;
  overflow: hidden;

  &.ng-dirty.ng-invalid {
    border-color: $primary-color;
    background-color: $light-red;
  }

  .kiss-select-container {
    padding: 0.313rem 0.75rem;
    min-height: 2.125rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 24px;
    background: $bg-color-3;
  }

  &--focus {
    border: 1px solid $primary-color;
  }

  &__post-icon {
    color: $text-color;
    margin: 0 0 0 0.813em;
  }

  &__pre-icon {
    color: $text-color;
    margin: 0 0.813em 0 0;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__placeholder {
    color: $text-color;
  }
}

.ooblee-select-filter .kiss-select {
  &__dropdown {
    border-radius: 0.25rem;
    padding: 0;
    box-shadow: $box-shadow-1;
  }

  &__option {
    padding: 0.438rem 0.75rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 24px;

    &--selected {
      background: lighten($color: $primary-color, $amount: 7);
      color: $text-color-6;
    }

    &--multiple {
      background: initial;
      color: initial;
    }

    &:hover:not(&--selected) {
      background: #e2e2e2;
      color: initial;
    }
  }
}

/* !OOBLEE-SELECT filter*/
