/* KISS NAVIGATION SIDEBAR */

.kiss-navigation-sidebar {
  border-right: 1.5px solid $border-2;
  overflow: hidden;

  &__ooblee-footer {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    line-height: 20px;
    padding: 20px 25px 50px 25px;
    justify-content: flex-end;
    white-space: nowrap;

    &__btn {
      cursor: pointer;
    }

    &__link {
      color: $text-color-5;
      margin-top: 15px;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .nav-expand-btn {
    position: absolute;
    top: 8rem;
    right: -0.781rem;
    width: 1.563rem;
    height: 1.563rem;

    background: $bg-color-3;
    border-radius: 50%;
    border: 1.5px solid $border-2;
    color: $text-color;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 150ms;
    transform: rotate(180deg);

    &__expanded {
      transform: rotate(0deg);
    }
  }

  .kiss-navigation {
    padding: 0 0 1em 0;
    margin-top: 1em;
    flex: 1 1;

    .kiss-navigation-item {
      display: block;
      margin: 0.5em 1.25em;

      .nav-link {
        padding: 0 0.625em;
        min-height: 2.5rem;
        border-radius: 0.5em;
        font-weight: 400;

        .nav-title,
        .nav-translation {
          padding: 0 0.714em;
        }
      }

      .nav-icon {
        margin: 0;
        flex: 0 0 1.5rem;
        width: 1.5rem;
        height: 1.5rem;
      }

      &.active {
        .nav-link {
          color: $primary-color;
          background-color: $light-red;
          font-weight: 600;
        }
      }

      &:not(.active) {
        .nav-link:hover {
          background-color: $light-red;
          color: $primary-color;
        }
      }
    }

    .kiss-navigation-list {
      display: block;
      margin: 0.5em 1.25em;

      .kiss-navigation-list-child {
        margin: 0em;
      }

      .nav-list {
        padding: 0 0.625em;
        min-height: 2.5rem;
        border-radius: 0.5em;
        font-weight: 400;

        > .nav-title,
        > .nav-translation {
          padding: 0 0.714em;
        }
      }

      .nav-icon,
      .nav-list-icon {
        margin: 0;
        flex: 0 0 1.5rem;
        width: 1.5rem;
        height: 1.5rem;
      }

      .nav-list-icon {
        transform: rotate(270deg);
        svg {
          width: 0.875rem;
          height: 0.875rem;
        }
      }

      .nav-list-item {
        flex: 0 0 0.5rem;
        width: 0.5rem;
        height: 0.5rem;
        transform: rotate(270deg);
      }

      &.active {
        > .nav-list {
          color: $primary-color;
          background: $light-red;
          font-weight: 600;
        }
      }

      &:not(.active) {
        > .nav-list:hover {
          background: $light-red;
          color: $primary-color;
        }
      }

      &.open {
        .nav-list-icon {
          transform: rotate(0deg);
        }
      }
    }

    .nav-children {
      padding-left: 1.75rem;
      overflow-y: hidden;

      .nav-link {
        min-height: 2rem;
        margin: 0.5em 0;
      }

      /* NESTED KISS NAVIGATION LIST */
      .nav-list {
        padding: 0 0.625em;
        min-height: 2rem;
        margin: 0.5em 0;

        > .nav-icon {
          flex: 0 0 0.5rem;
          width: 0.5rem;
          height: 0.5rem;
        }

        > .nav-list-icon {
          transform: rotate(0deg);

          svg {
            width: 0.625rem;
            height: 0.625rem;
          }
        }
      }

      .kiss-navigation-list {
        > .nav-children {
          padding-left: 1.25rem;
        }
      }
      /*!NESTED KISS NAVIGATION LIST */

      /* NESTED KISS NAVIGATION ITEM */
      .kiss-navigation-item {
        &.active {
          .nav-link {
            color: $primary-color;
            background-color: transparent;
          }
        }

        &:not(.active) {
          .nav-link:hover {
            background-color: transparent;
            color: $primary-color;
          }
        }
      }
      /* !NESTED KISS NAVIGATION ITEM */
    }
  }
}

.main-nav-sidebar {
  overflow: visible;

  @media (min-width: 576px) {
    width: 18.75rem;
    max-width: 18.75rem;
  }

  &.folded {
    .kiss-navigation-item,
    .kiss-navigation-list {
      margin: 0.5rem 1rem;

      .nav-icon {
        width: 2.5rem;
        height: 2.5rem;
        flex: 0 0 1.25rem;
      }
    }

    .nav-expand-btn {
      top: 4.55rem;
    }
  }
}
/* !KISS NAVIGATION SIDEBAR */
