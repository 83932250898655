.kiss-checkbox {
  &__checkmark-container {
    outline: 2px solid $border-3;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    user-select: none;
  }

  &__content {
    user-select: none;
  }

  &__label {
    display: flex;
    align-items: center;
    user-select: all;
  }

  &__checkmark {
    background-color: $primary-color;

    svg {
      height: 10px;
    }
  }

  &.checked {
    .kiss-checkbox__checkmark-container {
      outline-color: $primary-color;
      background-color: $primary-color;
    }
  }
}
