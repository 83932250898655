.kiss-datepicker {
  display: block;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.3;
  cursor: pointer;
  background-color: transparent;

  .kiss-datepicker-container {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  &__label-container {
    flex-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  &__dropdown-container {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.3;
  }

  &__dropdown {
    display: flex;
    flex-direction: column;
    margin: 5px 0;
    position: relative;
    background: #ffffff;
    overflow: hidden;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 30%);
    border-radius: 4px;
    padding: 2px 0px;
    width: 100%;
  }

  &__dropdown-header {
    display: flex;
    justify-content: space-between;
  }

  &__btn {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    line-height: inherit;
    user-select: none;

    &:hover {
      background: lightgray;
    }
  }

  &__view-info-date {
    display: flex;

    &__month,
    &__year {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__actions {
    display: flex;

    &__prev,
    &__next {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__view-container {
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
  }

  &__view {
    &--active {
      background: lightgray;
    }
  }

  &__month {
    max-width: 260px;

    &__item {
      border: 1px solid transparent;
      width: 33.33%;

      &--current {
        border: 1px solid lightgray;
      }
    }
  }

  &__year {
    max-width: 260px;

    &__item {
      border: 1px solid transparent;
      width: 25%;

      &--current {
        border: 1px solid lightgray;
      }
    }
  }

  &__day {
    min-width: 260px;

    &__weekdays {
      display: flex;

      &__item {
        width: 14.28%;
        padding: 2px;
        text-align: center;
      }
    }

    &__row {
      display: flex;
    }

    &__item {
      width: 14.28%;
      padding: 2px;
      text-align: center;
      border: 1px solid transparent;

      &--prev {
        opacity: 0.5;
      }

      &--current {
        border: 1px solid lightgray;
      }

      &--between-range {
        background: lightgray;
      }

      &--between-range-hover {
        background: rgba(243, 243, 243, 0.521);
      }

      &--active {
        background: lightgray;
      }
    }
  }

  &__time {
    min-width: 260px;
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__item {
        display: flex;
      }
    }

    &--inbetween {
      display: flex;
      align-items: center;
    }

    &__item {
      width: 2.5rem;
      display: flex;
      flex-direction: column;

      &__input {
        min-width: 0;
        border: 1px solid lightgray;
        text-align: center;

        &.ng-invalid:not(.ng-pristine) {
          border-color: red;
        }
      }

      &--up,
      &--down {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
      }

      &--up {
        margin-bottom: 0.25rem;
      }

      &--down {
        margin-top: 0.25rem;
      }
    }
  }
}
