.ooblee-stepper {
  .ooblee-stepper-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 60px 60px 60px;
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;

    &__item {
      $this: &;
      background: #f2f3f4;
      border: 1px solid rgba(131, 131, 131, 0.7);
      color: $text-color;
      width: 31px;
      height: 31px;
      flex: 0 0 31px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      cursor: pointer;
      position: relative;

      .stepper-label-text-container {
        position: absolute;
        top: 40px;
      }

      .stepper-label-text {
        position: relative;
        color: $text-color-2;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        width: 200px;

        @include media($from: 0, $until: 'md') {
          width: 120px;
        }
      }

      .check {
        width: 16px;
        height: 16px;
      }

      &--active {
        background: $primary-color;
        border: 1px solid $primary-color;
        color: $text-color-6;

        .stepper-label-text {
          color: $text-color;
        }
      }

      &--completed {
        background: $primary-color;
        border: 1px solid $primary-color;
        color: $text-color-6;

        .stepper-label-text {
          color: $text-color;
        }
      }

      &:not(#{$this}--active, #{$this}:disabled, #{$this}--completed) {
        &:hover {
          background: $light-red;
          color: $primary-color;
          border: 1px solid $primary-color;
        }
      }
    }

    &__line {
      border: 1px solid rgba(131, 131, 131, 0.7);
      flex: 1 1;
    }
  }
  &__step {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    .title {
      font-size: 13px;
      font-weight: 400;
      line-height: 17.7px;
      color: #4d4d4d;
    }
  }
  // &__content {
  //   @extend .kiss-card;
  // }
}
