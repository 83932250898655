.kiss-flex-position {
  display: flex;

  &__top {
    flex-direction: column !important;
  }

  &__bottom {
    flex-direction: column-reverse !important;
  }

  &__left {
    flex-direction: column !important;
  }

  &__right {
    flex-direction: row-reverse !important;
  }
}
