/* KISS TOOLBAR */

.kiss-toolbar {
  background: var(--pallete-white, #ffffff);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  z-index: 3;

  &__user-btn {
    margin: 0 1em 0 1.563em;
    padding: 0.5em;
    color: $text-color-2;
    .ooblee-btn__icon {
      margin-left: 0.938em;
    }
  }

  &__user-container {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: white;
    border: 0.5px solid #838383;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }

  .input_div {
    position: relative;
    width: 100%;

    .elastic_input {
      max-width: 439px;
      width: 100%;
      height: 34px;
      background: #ffffff;
      border: 1px solid rgba(131, 131, 131, 0.4);
      border-radius: 8px;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      outline: none;
      padding-left: 30px;
      color: #4d4d4d;
    }
    .elastic_input:focus {
      border: 1px solid #ff6067;
    }

    .absolute_search {
      position: absolute;

      left: 18px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__action-btn {
    width: 2.5rem;
    height: 2.5rem;

    margin-left: 0.5em;
    color: var(--pallete-midnight-blue, #193779);

    &--open {
      background: #e5f9ff;
      border-radius: 50%;
    }
  }

  &__action-btn:hover {
    background: #e5f9ff;
    border-radius: 50%;
  }

  .ooblee-transparent-select.kiss-select {
    .kiss-select-container {
      &:hover {
        background: $bg-color-9;
      }
    }
  }
}

.adjust {
  padding-top: 10px;
  padding-bottom: 10px;
}

.user_settings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  min-width: 280px;
  width: 100%;

  background: #ffffff;

  border-radius: 8px;

  .title {
    margin: 16px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    width: 57px;
    height: 24px;

    color: #232d42;
  }

  .line {
    margin: 0;
    width: 100%;
    height: 1px;
    background: #e2e2e2;
    border-radius: 2px;
  }

  .options_row {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding-left: 16px;

    width: 280px;
    height: 44px;
    margin: 0;

    p {
      padding-left: 14px;
      /* Link Small */

      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      margin: 0;

      align-self: center;
      color: #586278;
    }
  }

  .options_row:hover {
    background-color: #f2f6ff;
  }
}

.notification_box {
  max-height: 411px;
  height: 100%;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .title {
      margin-left: 16px;
      margin-top: 16px;
      margin-bottom: 8px;
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      width: 57px;
      height: 24px;

      color: #232d42;
    }

    .switch {
      display: flex;
      flex-direction: row;
      margin-right: 20px;

      .new {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #586278;
        cursor: pointer;
        margin-top: 16px;
        margin-bottom: 8px;

        &__active {
          color: #00b0f0;
        }
      }

      .new:hover {
        color: #00b0f0;
      }

      .all {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        margin-left: 20px;
        margin-top: 16px;
        margin-bottom: 8px;

        cursor: pointer;

        color: #586278;

        &__active {
          color: #00b0f0;
        }
      }

      .all:hover {
        color: #00b0f0;
      }
    }
  }

  .line {
    margin: 0;
    width: 100%;
    height: 1px;
    background: #e2e2e2;
    border-radius: 2px;
  }

  .notification_container {
    padding: 18px 18px 18px 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 358px;
    overflow-y: scroll;

    .color_box {
      margin: -18px -18px 15px -16px;
      padding-bottom: 10px;

      .notification {
        margin: 18px 18px 0px 16px;

        .notification_header {
          display: flex;
          justify-content: space-between;
          padding-bottom: 10px;

          .kiss-svg-icon__svg {
            width: 15px;
            height: 17px;
            cursor: pointer;
          }

          .circle_area {
            display: flex;
            width: fit-content;
            justify-content: space-between;
            align-items: center;

            p {
              margin: 0;
              font-family: 'Lato', sans-serif;
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
              color: #232d42;
            }

            .circle_border {
              width: 12px;
              height: 12px;
              border-radius: 100%;
              justify-content: center;
              align-items: center;
              margin-left: 9px;
              display: flex;

              .circle {
                cursor: pointer;
                width: 8px;
                height: 8px;
                border-radius: 100%;
                justify-self: center;
                position: relative;

                &__marked {
                  background-color: #00b0f0;
                }

                .tooltiptext {
                  transition-delay: 0ms;

                  transition-property: visibility;
                  user-select: none;
                  visibility: hidden;
                  text-align: center;
                  /* Body Small */
                  font-family: 'Lato', sans-serif;
                  width: 111px;
                  height: 20px;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  color: #586278;
                  background: #ffffff;
                  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
                  border-radius: 5px;
                  top: 6px;
                  left: 6.6px;
                  /* Position the tooltip text - see examples below! */
                  position: absolute;
                  z-index: 1;
                }
              }
            }

            .circle_border:hover {
              border: 1px solid #adb5bd;
            }

            .circle:hover .tooltiptext {
              visibility: visible;
              transition-delay: 600ms;
            }
          }
        }

        .message {
          margin: 0;
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          inline-size: 370px;
          overflow-wrap: break-word;
          hyphens: manual;

          color: #232d42;
        }

        .date {
          margin: 0;
          padding-top: 2px;
          font-family: 'Lato', sans-serif;
          font-style: italic;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #828282;
          text-align: right;
        }
      }
    }
  }

  .color_box:last-child {
    margin: -18px -18px 0px -16px;
  }

  .color_box:hover {
    background-color: #f2f6ff;
  }
}

.user-info-container {
  display: flex;
  align-items: center;

  padding: 1.125em 1em;
  margin-bottom: 0.5em;
  border-bottom: 1px solid var(--pallete-gray-5, #e2e2e2);
}

.kiss-menu-content {
  padding: 0 0 0.5em 0;

  .user-info {
    display: flex;
    flex-direction: column;
    padding-left: 1em;

    &__email {
      font-size: 0.875rem;
      color: var(--primary-text-color-2, #586278);
    }
  }
}

/* !KISS TOOLBAR */
