.kiss-navigation-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;

  .nav-container {
    padding: 26px 12px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    margin-bottom: 10px;
  }

  .nav-logo {
    max-height: 40px;
    height: 100%;

    img {
      width: auto;
      height: 100%;
    }

    &__folded {
      display: none;
    }
  }
}
