.ooblee-tab {
  &__header {
    min-height: 42px;
    display: flex;
    padding: 10px 10px 0 10px;
    border-bottom: 1px solid $border-2;
    overflow: auto;

    &__item {
      display: flex;
      align-items: center;
      padding: 10px 20px 16px 20px;
      border: none;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      background: transparent;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: $text-color-3;
      margin-right: 20px;

      &--active {
        color: $primary-color;
        border-bottom: 2px solid $primary-color;
        text-shadow: 0 0 0.6px $primary-color, 0 0 0.6px $primary-color;
      }
    }
  }
}

.ooblee-tab-subgroup {
  .ooblee-tab {
    &__header {
      min-height: 42px;
      display: flex;
      border-bottom: none;
      overflow: auto;
      padding: 0;

      &__item {
        display: flex;
        align-items: center;
        padding: 17px 21px 17px 19px;
        border: none;
        border-bottom: none;
        cursor: pointer;
        background: transparent;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: $text-color-3;
        margin-right: 0;
        border: 1px solid transparent;
        border-bottom: 0px;

        &::before {
          content: '';
          width: 9px;
          height: 9px;
          background: $gray-7;
          border-radius: 50%;
          margin-right: 8px;
          opacity: 0;
        }

        &--active {
          background: $bg-color-2;
          color: $text-color;
          border: 1px solid $border-2;
          border-radius: 8px 8px 0px 0px;
          border-bottom: 0px;
          text-shadow: 0 0 0.6px $text-color, 0 0 0.6px $text-color;

          &::before {
            opacity: 1;
          }
        }
      }
    }

    &__content {
      background: $bg-color-2;
      border: 1px solid $border-2;
      border-radius: 0px 0px 8px 8px;
      margin-top: -1px;
    }
  }
}
