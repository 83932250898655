/* KISS SEARCH */
.kiss-search {
  border: 1px solid $border-2;
  padding: 5px 0;
  border-radius: 0.5rem;

  &:focus-within {
    outline: 2px solid $primary-color;
  }

  &__icon-btn {
    margin: 0;
    color: $border-5;
  }
}
/* !KISS SEARCH */
