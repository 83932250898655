/* KISS-DATEPICKER-TIME */
.ooblee-datepicker-time .kiss-datepicker {
  &__dropdown {
    padding: 1em;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__time {
    min-width: 200px;

    &--inbetween {
      font-weight: 400;
      font-size: 32px;
      line-height: 44px;
      color: $primary-color;
    }

    &__item {
      margin: 1em 0.5em;
      width: 3.5em;

      &--up,
      &--down {
        color: $text-color-3;
        font-weight: 900;
        font-size: 15px;
        line-height: 15px;
      }

      &__input {
        border: none;
        padding: 0.2em;
        font-size: 32px;
        color: $primary-color;
      }
    }
  }

  &__btn {
    border-radius: 0.2em;
    padding: 0 0.5em;
    height: 2.25rem;

    font-weight: 500;

    line-height: 22px;
    font-size: 0.875rem;

    &:hover {
      background: $gray-6;
      color: initial;
    }
  }
}
/*! KISS-DATEPICKER-TIME */
