.ooblee-transparent-select.kiss-select {
  border-radius: 0.5rem;
  color: $text-color;
  overflow: hidden;

  .kiss-select-container {
    padding: 0.625em;
    font-weight: 400;
    font-size: 0.938rem;
    line-height: 20px;
    background: transparent;
  }
}

.ooblee-transparent-select .kiss-select {
  &__post-icon {
    color: $text-color;
    margin: 0 0 0 0.813em;
  }

  &__pre-icon {
    color: $text-color;
    margin: 0 0.813em 0 0;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__placeholder {
    color: #757575;
  }

  &__dropdown {
    border-radius: 0.25rem;
    box-shadow: $box-shadow-1;
    padding: 0;
    min-width: 200px;

    .kiss-checkbox {
      margin-right: 12px;
    }
  }

  &__dropdown-header {
    .kiss-checkbox {
      &__content {
        margin-left: 12px;
      }
    }
  }

  &__option {
    padding: 0.438rem 0.75rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 24px;

    &--selected:not(&--multiple) {
      background: lighten($color: $primary-color, $amount: 7);
      color: $text-color-6;
    }

    &:hover:not(&--selected) {
      background: #e2e2e2;
      color: initial;
    }
  }
}
