// https://dev.to/nidhishs/5-scss-mixins-to-check-out-2bkn#media-queries

// Custom Breakpoints
$breakpoints: (
  sm: 57.6em,
  md: 76.8em,
  xl: 120em,
  xxl: 160em,
);

// Media-Query Mixin
@mixin kiss-media($from: false, $until: false, $and: false, $media-type: all) {
  $min-width: 0;
  $max-width: 0;
  $query: '';

  //FROM: this breakpoint (inclusive)
  @if $from {
    @if type-of($from) == number {
      $min-width: $from;
    } @else {
      $min-width: map-get($breakpoints, $from);
    }
  }

  //UNTIL: this breakpoint (exclusive)
  @if $until {
    @if type-of($until) == number {
      $max-width: $until - 1px;
    } @else {
      $max-width: map-get($breakpoints, $until) - 1px;
    }
  }

  @if $min-width != 0 {
    $query: '#{$query} and (min-width: #{$min-width})';
  }
  @if $max-width != 0 {
    $query: '#{$query} and (max-width: #{$max-width})';
  }
  @if $and {
    $query: '#{$query} and (#{$and})';
  }

  @if ($media-type == 'all' and $query != '') {
    $media-type: '';
    $query: str-slice(unquote($query), 6);
  }

  @media #{$media-type + $query} {
    @content;
  }
}

@mixin kiss-xs {
  @include media($from: 0, $until: 'sm') {
    @content;
  }
}

@mixin kiss-sm {
  @include media($from: 'sm', $until: 'md') {
    @content;
  }
}

@mixin kiss-md {
  @include media($from: 'md', $until: 'xl') {
    @content;
  }
}

@mixin kiss-xl {
  @include media($from: 'xl', $until: 'xxl') {
    @content;
  }
}

@mixin kiss-xxl {
  @include media($from: 'xxl', $until: 100%) {
    @content;
  }
}
