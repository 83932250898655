/* You can add global styles to this file, and also import other style files */
/* Kiss styles */
@import '@kiss/styles/main';

/* Main style */
@import 'scss/main';

/* Swiper */
@import '~swiper/scss';
@import '~swiper/css/pagination';
*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-family: $sans;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: $text-color;
  background-color: $bg-color-3 !important;
  scroll-behavior: smooth;
}

// /* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #d1d2d3;
}

// /* Works on Chrome, Edge, and Safari */

/* width */
::-webkit-scrollbar {
  width: 18px;
  height: 18px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fcfcfc;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e7e8e9;
  border-radius: 13px;
  border: 5px solid #fcfcfc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d1d2d3;
}

/* !SCROLLBAR */

.confirmation-dialog {
  .kiss-dialog__content-container {
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }

  .kiss-dialog__content {
    background-color: $bg-color-3;
    border-radius: 4px;
    max-width: 450px;
    width: 100%;
  }
}

.suport-dialog {
  width: 506px;
  height: 503px;
  .kiss-dialog__content-container {
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }

  .kiss-dialog__content {
    background-color: $bg-color-3;
    border-radius: 4px;
    max-width: 450px;
    width: 100%;
  }
}

/* NGX-TOASTR */
.ngx-toastr {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .toast-close-button {
    top: 50%;
    right: 5px;
    order: 2;
  }

  .alert {
    flex: 1 1;
  }
}
/* !NGX-TOASTR */

.text-elipsis-two-row {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-elipsis-one-row {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.text-elipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
