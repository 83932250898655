.kiss-select {
  display: block;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.3;
  cursor: pointer;
  background-color: transparent;

  .kiss-select-container {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  &__label-container {
    flex-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  &__dropdown-container {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.3;
  }

  &__dropdown {
    margin: 5px 0;
    width: 100%;
    position: relative;
    background: #ffffff;
    overflow: hidden;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 30%);
    border-radius: 4px;
    padding: 2px 0px;
  }

  &__dropdown-header {
    display: flex;
    flex-direction: column;

    kiss-search {
      margin: 8px 8px 4px 8px;
    }

    kiss-checkbox {
      padding: 8px;
    }
  }

  &__option-container {
    max-height: 200px;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  &__option {
    padding: 12px 8px;
    border: none;
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;

    &--hidden {
      display: none;
    }
  }
}
