.kiss-loader {
  &__circle {
    display: inline-block;
    position: relative;
    user-select: none;
    border-color: white transparent transparent transparent;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 0.8em;
      height: 0.8em;
      margin: 0.1em;
      border: 0.1em solid transparent;
      border-radius: 50%;
      animation: circle-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: inherit;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }

      &:nth-child(2) {
        animation-delay: -0.3s;
      }

      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }

    @keyframes circle-animation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
