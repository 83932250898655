// Custom Breakpoints
// EM IS CONSISTENT ACROSS ALL BROWSERS

$max-width-breakpoint: 120em;

$sans: 'Open Sans', sans-serif;
$base-url: '/assets/' !default;

//colors
$primary-color: #ff6067;
$dark-green: #3e834e;
$pastel-green: #6ed889;
$dark-yellow: #767021;
$light-green: #e2f7e7;
$light-green-hover: #d4e9d9;
$light-red: #ffeeee;
$light-yellow: #fffbc1;
$gray-6: #ebecf0;
$gray-7: #d2d2d2;
$warning: #ffae42;
$error: #ff0000;

$text-color: #4d4d4d;
$text-color-2: #838383;
$text-color-3: #828282;
$text-color-4: #000000;
$text-color-5: #4db9b3;
$text-color-6: #ffffff;
$text-color-7: #9b9b9b;
$text-color-8: #777777;

//backgrounds
$bg-color: #f6f7f8;
$bg-color-2: #f9f9f9;
$bg-color-3: #ffffff;
$bg-color-4: rgba(0, 0, 0, 0.5);
$bg-color-5: rgba(255, 96, 103, 0.05);
$bg-color-6: #cdcdcd;
$bg-color-7: rgba(255, 96, 103, 0.18);
$bg-color-8: rgba(255, 96, 103, 0.09);
$bg-color-9: #fbfcfd;
$bg-color-10: rgba(255, 96, 103, 0.04);
$bg-color-11: #f8f8f8;

//border
$border-1: #d5d5d5;
$border-2: #ebecf0;
$border-3: rgba(130, 130, 130, 0.4);
$border-4: #cdcdcd;
$border-5: #828282;
$border-6: #838383;
$border-7: #ebebeb;

//shadow
$box-shadow-1: 0px 0px 8px rgba(0, 0, 0, 0.12);

//opacity
$primary-color-05: #ff60670d;
