.kiss-svg-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  &__svg {
    width: inherit;
    height: inherit;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }
}
