/* APPLYING A SPECIFIC STLYING */

kiss-table.ooblee-table {
  .kiss-table-header {
    .kiss-header-row {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;

      .kiss-header-cell {
        min-height: 40px;
        padding: 0px 15px;
        background-color: $bg-color-2;

        &:first-of-type {
          padding-left: 30px;
        }
      }
    }
  }

  .kiss-table-body {
    .kiss-row {
      font-weight: 400;
      font-size: 14px;
      line-height: auto;

      .kiss-cell {
        min-height: 73px;
        padding: 0 15px;
        background-color: $bg-color-3;
        border-bottom: 1px solid $border-2;

        &:first-of-type {
          padding-left: 30px;
        }
      }
    }
  }

  .kiss-table-body {
    .kiss-row.highlight {
      .kiss-cell {
        background-color: transparent;
      }
    }
  }
}
