/* KISS DATEPICKER*/
.ooblee-datepicker.kiss-datepicker {
  border: 1px solid $border-4;
  border-radius: 0.5rem;
  color: $text-color;
  overflow: hidden;

  .kiss-datepicker-container {
    padding: 0.625rem;
    min-height: 2.438rem;
    min-width: 6.25rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 19px;
    background: $bg-color-3;
  }

  &--focus {
    border: 1px solid $primary-color;
  }

  &.ng-dirty.ng-invalid {
    border-color: $primary-color;
    background-color: $light-red;
  }
}

.ooblee-datepicker .kiss-datepicker {
  &__dropdown {
    padding: 1em;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__post-icon {
    color: $text-color-3;
    margin: 0 0 0 0.813em;
    width: 0.938rem;
    height: 0.938rem;

    kiss-svg-icon {
      width: inherit;
      height: inherit;
    }
  }

  &__pre-icon {
    color: $text-color-3;
    margin: 0 0.813em 0 0;
    width: 0.938rem;
    height: 0.938rem;

    kiss-svg-icon {
      width: inherit;
      height: inherit;
    }
  }

  &__btn {
    border-radius: 0.2em;
    padding: 0;
    height: 2.25rem;

    font-weight: 500;
    color: $text-color;
    line-height: 22px;
    font-size: 0.875rem;

    &:hover {
      background: $gray-6;
      color: initial;
    }
  }

  &__view-info-date {
    color: $text-color;

    &__month,
    &__year {
      font-size: 1rem;
      padding: 0.2em;
      height: 2.25rem;
    }
  }

  &__view {
    &--active {
      background: $primary-color;
      color: $text-color-6;
    }
  }

  &__year,
  &__month {
    &--active {
      background: $primary-color;
      color: $text-color-6;
    }
  }

  &__day {
    font-size: 0.85rem;

    &__weekdays {
      font-weight: 600;

      &__item {
        width: 2.25rem;
        height: 2.25rem;
        color: $text-color-3;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__item {
      width: 2.25rem;
      height: 2.25rem;
      border-radius: 50%;

      &--between-range {
        background: lighten($color: $primary-color, $amount: 7);
        border-radius: 0;
      }

      &--between-range-hover {
        border-radius: 0;
      }

      &--active {
        background: $primary-color;
        color: $text-color-6;
      }

      &--disabled {
        opacity: 0.5;
      }
    }
  }

  &__placeholder {
    color: $text-color-3;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 19px;
  }

  &__time {
    &--inbetween {
      font-weight: 400;
      font-size: 32px;
      line-height: 44px;
      color: $primary-color;
    }

    &__item {
      margin: 1em 0.5em;
      width: 3.5em;

      &--up,
      &--down {
        color: $text-color-3;
        font-weight: 900;
        font-size: 15px;
        line-height: 15px;
      }

      &__input {
        border: none;
        padding: 0.2em;
        font-size: 32px;
        color: $primary-color;
      }
    }
  }
}

/* !KISS DATEPICKER */
