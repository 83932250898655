/* KISS CARD */
.kiss-card {
  background: $bg-color-3;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.04);
  border-radius: 16px;

  &--half {
    border-radius: 8px;
  }
}
/* !KISS CARD */
