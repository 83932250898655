@import '~scss/abstracts/variables';

.kiss-table {
  &__type-table {
    display: table;
    width: 100%;

    .kiss-table-body {
      display: table-row-group;
    }

    .kiss-table-header {
      display: table-header-group;
    }

    .kiss-row,
    .kiss-header-row {
      display: table-row;
    }

    .kiss-cell,
    .kiss-header-cell {
      display: table-cell;
    }

    .kiss-header-cell {
      position: relative;

      &:hover .kiss-table__resize-handle {
        opacity: 1;
        transition: 0.3s ease-in-out;
      }
    }
  }

  &__type-flex {
    display: flex;
    flex-direction: column;
    position: relative;

    .kiss-table-body,
    .kiss-table-header {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    .kiss-row,
    .kiss-header-row {
      display: flex;
    }

    .kiss-cell,
    .kiss-header-cell {
      display: flex;
      flex: 1 1 150px;
      min-width: 150px;
      align-items: center;
    }

    .kiss-header-cell {
      position: relative;

      &__resize-handle {
        display: inline-block;
        border-right: 2px solid rgb(233, 233, 233);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        height: 14px;
        cursor: col-resize;
        opacity: 0;

        &:hover {
          width: 1.85rem;
          border-right: 2px solid rgb(114, 114, 114);
        }
      }

      &__show-resize-handle {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome, Edge, Opera and Firefox */

        .kiss-header-cell__resize-handle {
          opacity: 1;
        }
      }

      &:hover {
        .kiss-header-cell__resize-handle {
          opacity: 1;
          transition: 0.3s ease-in-out;
        }
      }
    }
  }

  &__sticky-header {
    .kiss-table-header {
      position: sticky;
      top: 0;
      z-index: 2;
    }
  }

  &.ooblee-table .kiss-table-body .kiss-row.highlight {
    background-color: $gray-6 !important;
  }
}
