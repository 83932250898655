.kiss-paginator {
  $this: &;

  &__item {
    width: 32px;
    height: 32px;
    background: transparent;
    border: none;
    font-weight: 600;
  }

  &__arrow-right {
    margin-left: 8px;
    color: $primary-color;

    kiss-svg-icon {
      width: 10px;
      height: 24px;
    }

    &[disabled] {
      color: $text-color-2;
    }
  }

  &__arrow-left {
    margin-right: 8px;
    color: $primary-color;

    kiss-svg-icon {
      width: 10px;
      height: 24px;
    }

    &[disabled] {
      color: $text-color-2;
    }
  }

  &__number {
    background: none;
    color: $text-color-2;
    margin: 0 5px;
    border-radius: 8px;

    &:not(#{$this}__active, #{$this}:disabled) {
      &:hover {
        background: $light-red;
        color: $primary-color;
      }
    }
  }

  &__active {
    background: $primary-color;
    color: white;
    font-weight: bold;
  }

  &__results {
    color: $text-color-2;
    font-size: 14px;
    line-height: 19px;

    .ooblee-select-filter {
      margin-left: 10px;
      min-width: 50px;

      .kiss-select-container {
        padding: 0.313rem 0.5rem !important;
      }
    }
  }
}
