.kiss-toggle-switch {
  font-size: 1.2rem;

  &__slider {
    background: $bg-color-6;

    &::before {
      background: $text-color-6;
    }
  }

  &.checked {
    .kiss-toggle-switch__slider {
      background: $primary-color;
    }
  }

  &--green {
    &.checked {
      .kiss-toggle-switch__slider {
        background: $pastel-green;
      }
    }
  }
}
